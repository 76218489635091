html, body, #root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.js-plotly-plot,
.js-plotly-plot .plot-container.plotly {
  width: 100%;
}

.js-plotly-plot .plot-container.plotly .svg-container {
  width: 100%;
  /* height: 'auto' !important; */
  /* min-height: 400px; */
  margin: auto;
}

#galaxyHeatmap .js-plotly-plot .plot-container.plotly .svg-container {
    width: 100%;
    /* height: 'auto' !important; */
    /* min-height: 400px; */
    margin: initial;
  }

.RootBase-root {
  height: 100%;
}
